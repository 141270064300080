@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600;700&display=swap');

html, body {
    font-family: 'Jura', sans-serif !important;
    font-weight: 700;
    height: 100%;
}

*::selection {
    color: #262831;
    background: #6dc353;
}

#root {
    height: 100%;
}

h1 {
    text-align: center;
    font-size: 24px;
}

h2 {
    text-align: center;
    font-size: 20px;
}

h3 {
    text-align: center;
    font-size: 16px;
}

p {
    line-height: 1.6;
    color: #262831;
}

.wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.app-wrapper-content {
    flex: 1 0 auto;
}

.two-column-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.links-column {
    min-width: 340px;
    max-width: 50%;
}

.sk-cube-grid {
    width: 40px;
    height: 40px;
    margin: 100px auto;
}

.sk-cube-grid .sk-cube {
    width: 33%;
    height: 33%;
    background-color: #202937;
    float: left;
    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube-inv {
    width: 33%;
    height: 33%;
    background-color: #6dc353;
    float: left;
    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
    background-color: #6dc353;
}

.sk-cube-grid .sk-cube5-inv {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
    background-color: #202937;
}

.sk-cube-grid .sk-cube6 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    }
    35% {
        -webkit-transform: scale3D(0, 0, 1);
        transform: scale3D(0, 0, 1);
    }
}

@keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    }
    35% {
        -webkit-transform: scale3D(0, 0, 1);
        transform: scale3D(0, 0, 1);
    }
}

footer {
    flex: 0 0 auto;
    margin-top: auto;
    background: #202938;
}

footer .container_footer {
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
    max-width: 1200px;
    clear: both;
    padding-top: 20px;
    padding-bottom: 5px;
    background-color: #202937;
}

@media screen and (max-width: 780px) {
    footer .container_footer {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.searchLabel {
    text-align: center;
}

footer .container_footer .logo {
    float: left;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

footer .container_footer .brand {
    margin-top: 7px;
    color: #6dc353;
    font-size: 16px;
    font-weight: 700;
}

footer .container_footer .copyright {
    font-size: 15px;
    color: #6dc353;
    text-align: center;
    font-weight: 400;
}

footer .container_footer .feedback {
    font-size: 15px;
    color: #6dc353;
    text-align: left;
    font-weight: 400;
}

.spinner {
    display: flex;
}

.img_container_404 {
    Display: flex;
    Align-Items: center;
    Justify-Content: center;
}